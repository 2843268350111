






























































import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import axios from "axios";
import {
  defineComponent,
  PropType,
  ref,
  useFetch,
  useContext,
  useRouter,
} from "@nuxtjs/composition-api";

import type { ImageModifiers } from "@nuxt/image";
import { SfButton } from "@storefront-ui/vue";
import { useConfig } from "~/composables";
import VueSlickCarousel from "~/components/VueSlickCarousel.vue";
import Preload from "~/components/Preload.vue";
import VLazyImage from "v-lazy-image/v2";
import SfImage from "~/modules/catalog/product/components/SfImage.vue";

export default defineComponent({
  name: "HeroSection",
  components: {
    VueSlickCarousel,
    SfButton,
    SfImage,
    Preload,
    VLazyImage,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    buttonText: {
      type: String,
      default: "",
    },
    imageHeight: {
      type: [Number, String],
      default: "",
    },
    imageSrc: {
      type: String,
      default: "",
    },
    imageTag: {
      type: String,
      default: "",
    },
    imageWidth: {
      type: [Number, String],
      default: "",
    },
    link: {
      type: String,
      default: "",
    },
    nuxtImgConfig: {
      type: Object as PropType<ImageModifiers | {}>,
      default: () => ({}),
    },
    bannerSlideId: {
      type: String,
      default: "",
    },
    banner_mobile_width: {
      type: String,
      default: "618",
    },
    banner_mobile_height: {
      type: String,
      default: "200",
    },
  },

  setup(props) {
    const { config } = useConfig();
    const slides = ref();
    const baseMediaUrl = config.value.secure_base_media_url;
    const baseUrl = config.value.secure_base_url;
    const swipeDetected = ref(true);
    const { app } = useContext();
    const router = useRouter();
    const isLoaded = ref(false);
    const { fetch } = useFetch(async () => {
      const headers = {
        Authorization: `Bearer ${process.env.MG_ACCESS_TOKEN}`,
      };

      if (props.bannerSlideId) {
        const response = await axios.get(
          `${baseUrl}rest/V1/utech/slider/${props.bannerSlideId}`,
          {
            headers,
          }
        );

        isLoaded.value = true;
        slides.value = response?.data[1];
      }
    });
    fetch();

    const linkBanner = (e: any, link: string) => {
      if (link && swipeDetected.value) {
        router.push(app.localePath(`/${link}`));
      }
    };
    const handleSlideBeforeChange = () => {
      swipeDetected.value = false;
    };
    const handleSlideAfterChange = () => {
      swipeDetected.value = true;
    };

    return {
      slides,
      baseMediaUrl,
      isLoaded,
      linkBanner,
      handleSlideBeforeChange,
      handleSlideAfterChange,
    };
  },
  data() {
    return {
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            dots: false,
          },
        },
      ],
    };
  },
});
