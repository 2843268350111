














































































import axios from "axios";
import Grid from "~/components/Blog/Grid.vue";
import Featured from "~/components/Blog/Featured.vue";
import Preload from "~/components/Preload.vue";
import {
  defineComponent,
  useRouter,
  useRoute,
  useContext,
  ref,
  useFetch,
} from "@nuxtjs/composition-api";
import { useConfig } from "~/composables";
import { Breadcrumb } from "~/modules/catalog/types";
import { SfBreadcrumbs, SfLink } from "@storefront-ui/vue";

export default defineComponent({
  name: "Blog",
  props: {
    blog_list: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Grid,
    Preload,
    Featured,
    SfBreadcrumbs,
    SfLink,
  },
  setup(props) {
    // Use composable function to access config data
    const { config } = useConfig();

    // Extract base media URL and base URL from config data
    const baseMediaUrl = config.value.secure_base_media_url;
    const baseUrl = config.value.secure_base_url;

    // Use ref to create reactive data properties
    const posts = ref([]);
    const isLoaded = ref(false);
    const blogList = ref(false);
    const isSearch = ref(false);
    const router = useRouter();
    const route = useRoute();
    const {
      localePath,
      localeRoute,
      app: { i18n },
    } = useContext();
    const { app } = useContext();
    const searchQuery = ref(null);

    const breadcrumbs = ref<Breadcrumb[]>([
      {
        text: i18n.t("Home") as string,
        link: localePath("/"),
      },
      {
        text: i18n.t("Blog") as string,
        link: localePath("/blog.html"),
      },
    ]);

    const querySearch = async (query: any) => {
      router.push(
        app.localeRoute({
          name: "Blog",
          path: "/blog.html",
          query: { q: query.target.value },
        })
      );
    };
    if (!route?.value?.query?.q) {
      const { fetch, fetchState } = useFetch(async () => {
        const headers = {
          Authorization: `Bearer ${process.env.MG_ACCESS_TOKEN}`,
        };
        let request_url = `${baseUrl}rest/V1/amasty_blog/categories/list`;
        if (props.blog_list === false) {
          request_url = `${baseUrl}rest/V1/amasty_blog/categories/list/?pageSize=6`;
        }
        const response = await axios.get(
          request_url,
          {
            headers,
          }
        );
        posts.value = response?.data;
        isLoaded.value = true;
        isSearch.value = true;
        blogList.value = props.blog_list;
      });
      fetch();
    } else {
      searchQuery.value = route?.value?.query?.q ?? "";
      blogList.value = props.blog_list;
      isLoaded.value = true;
      isSearch.value = true;
    }

    return {
      posts,
      isLoaded,
      blogList,
      querySearch,
      searchQuery,
      isSearch,
      breadcrumbs,
    };
  },
});
