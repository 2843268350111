import { render, staticRenderFns } from "./ContentBlock.vue?vue&type=template&id=7b15cbb8&"
import script from "./ContentBlock.vue?vue&type=script&lang=ts&"
export * from "./ContentBlock.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports